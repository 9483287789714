import React from 'react'
import {
  Box
} from '@chakra-ui/react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

import StepItem from './StepItem'

const options = {
  renderNode: {
    [MARKS.BOLD]: (_node, children) => (
      <b>{children}</b>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => <span>{children}</span>
  }
}

const StepsSection = ({ props }) => (
  <Box as='section' bg='white' pt='4' pb='4'>
    <Box
      maxW={{
        base: '2xl',
        md: '7xl'
      }}
      mx='auto'
      px={{
        base: '6',
        md: '8'
      }}
      marginTop='40'
      marginBottom='20'
    >
      <Box
        color='black'
        fontSize={{ base: '6xl', lg: '7xl' }}
        fontWeight='semibold'
        lineHeight='none'
        letterSpacing='tight'
        sx={{
          b: {
            color: 'teal.400'
          }
        }}
      >
        {renderRichText(props.sectionTitle, options)}
      </Box>
      <Box
        spacing='2rem'
        mt='8'
        align='center'
        justify='space-between'
        display='flex'
        flexWrap='wrap'
        justifyContent='space-between'
      >
        {props.stepsItem.map((step, index) => (
          <StepItem key={index} {...step} needBoxShadow={index === 1} />
        ))}
      </Box>
    </Box>
  </Box>
)

export default StepsSection
