import React from 'react'
import {
  Box,
  Heading,
  Text,
  Wrap
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const StepItem = ({ ...props }) => (
  <Box
    key={props.title}
    backgroundColor='gray.50'
    p='10'
    width={{ base: 'full', lg: '48%' }}
    mb='4'
    flexDirection='column'
    display='flex'
    borderRadius='lg'
    boxShadow='md'
  >
    <Heading
      as='h2'
      size='3xl'
      color='gray.800'
      fontWeight='bold'
      letterSpacing='tight'
      fontSize='3xl'
    >
      {props.title}
    </Heading>
    <Text
      color='gray.600'
      mt='4'
      fontSize='lg'
      fontWeight='normal'
      marginBottom={8}
    >
      {props.text}
    </Text>
    {/* FIXME: 1 image included boxShadow – 2 no (need boxShadow on both) */}
    <Wrap
      boxShadow={props.needBoxShadow ? 'dark-lg' : 'none'}
      borderRadius='2xl'
    >
      <GatsbyImage
        image={getImage(props.image.gatsbyImageData)}
        alt={props.title || ''}
        style={{ width: '100%' }}
      />
    </Wrap>
  </Box>
)

export default StepItem
