import React from 'react'
import {
  Box,
  Stack,
  Heading,
  Text,
  Button,
  Hide
} from '@chakra-ui/react'
import { HiPlay } from 'react-icons/hi'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { actions } from '../data'

const ScreenHero = ({ props, openVideoModal }) => (
  <Box as='section' bg='white' py='4'>
    <Box>
      <Stack
        direction={{
          base: 'column',
          lg: 'row'
        }}
        spacing={{
          base: '3rem',
          lg: '2rem'
        }}
        mt='8'
        align={{
          lg: 'center'
        }}
        justify='space-between'
      >
        <Box
          flex='1'
          w='full'
          px={{ base: '12', lg: '28' }}
          pt={{ base: '0', lg: '28' }}
          pb={{ base: '12', lg: '28' }}
        >
          <Heading
            as='h2'
            size='3xl'
            color='black'
            fontWeight='bold'
            letterSpacing='tight'
            fontSize='5xl'
          >
            {props.title}
          </Heading>

          <Text
            color='gray.600'
            mt='4'
            fontSize='lg'
            fontWeight='medium'
          >
            {props.description}
          </Text>
          <Hide below='lg'>
            <Stack
              direction={{
                base: 'column',
                lg: 'row'
              }}
              spacing='4'
              mt='8'
            >
              <Button
                size='lg'
                colorScheme='blue'
                height='14'
                px='8'
                onClick={actions.handleSignUp}
              >
                {props.freePlanButton.text}
              </Button>
              <Button
                size='lg'
                bg='white'
                color='gray.700'
                _hover={{
                  bg: 'gray.50'
                }}
                height='14'
                px='8'
                shadow='base'
                leftIcon={<Box as={HiPlay} fontSize='2xl' />}
                onClick={openVideoModal}
              >
                {props.demoButton.text}
              </Button>
            </Stack>
          </Hide>
          <Text
            color='gray.600'
            mt='12'
            fontSize='lg'
            fontWeight='medium'
          >
            {props.noCardText}
          </Text>
        </Box>
        <Box
          pos='relative'
          w={{
            base: 'full',
            lg: '50%'
          }}
          h={{
            base: 'auto',
            lg: '100%'
          }}
        >
          <GatsbyImage
            image={getImage(props.heroImage.gatsbyImageData)}
            alt={props.title || ''}
          />
        </Box>
      </Stack>
    </Box>
  </Box>
)

export default ScreenHero
