import React from 'react'
import {
  Box,
  Heading,
  Img,
  Stack,
  Text
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import FrameImage from '../../images/frame-right.png'
import FrameImageLeft from '../../images/frame-left.png'

const WhyUse = ({ props }) => (
  <Box>
    <Box backgroundColor='#000' padding='120px 0px 50px 0px'>
      <Heading width='auto' color='#FFFF' textAlign='center'>
        {props.title}
      </Heading>
    </Box>
    <Box backgroundColor='#000' padding='50px 0px'>
      <Box
        maxW={{
          base: '2xl',
          md: '7xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '8'
        }}
      >
        <Stack
          direction={{
            base: 'column',
            lg: 'row'
          }}
          spacing={{
            base: '3rem',
            lg: '2rem'
          }}
          mt='8'
          align={{
            lg: 'center'
          }}
          justify='space-between'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            flexWrap='wrap'
            className='main-recruiters'
          >
            <Img
              src={FrameImageLeft}
              position='absolute'
              left='-100px'
              className='recruiters-img'
              marginTop='-110px'
            />
            <Img
              src={FrameImage}
              position='absolute'
              right='0px'
              marginTop='-110px'
              className='recruiters-img'
            />
            {props.useCaseCards.map(card => (
              <Box
                flex='1'
                backgroundColor='#FFF'
                padding='30px'
                borderRadius='10px'
                margin='10px 10px'
                minWidth='500px'
                className='box-recruiters'
                key={card.title}
              >
                <GatsbyImage
                  image={getImage(card.icon.gatsbyImageData)}
                  alt={card.title || ''}
                />
                <Heading
                  fontSize='14px'
                  fontWeight='bold'
                  lineHeight='2.5rem'
                >
                  {card.title}
                </Heading>
                <Text fontSize='16px' fontWeight='400' color='#4A5568'>
                  {card.content}
                </Text>
              </Box>
            ))}
          </Box>
        </Stack>
      </Box>
    </Box>
  </Box>
)

export default WhyUse
