import React from 'react'
import { Box, Button, Heading, Hide, Stack } from '@chakra-ui/react'
import { HiPlay } from 'react-icons/hi'

import { actions } from '../data'

const FindBetterApplicantsSection = ({ props }) => (
  <Box
    as='section'
    bg='white'
    py='4'
    mt='24'
    mx={{ base: '2rem', lg: 'auto' }}
    display='flex'
    justifyContent='center'
    alignItems='center'
    flexDirection='column'
  >
    <Heading
      as='h2'
      size='3xl'
      color='gray.800'
      fontWeight='bold'
      letterSpacing='tight'
      textAlign='center'
      width={{ base: 'auto', lg: 'xl' }}
      mb='8'
    >
      {props.title}
    </Heading>
    <Hide below='lg'>
      <Stack
        direction={{
          base: 'column',
          md: 'row'
        }}
        spacing='4'
        mt='8'
      >
        <Button
          size='lg'
          minW='210px'
          colorScheme='blue'
          height='14'
          px='8'
          onClick={actions.handleOnboarding}
        >
          {props.startForFreeButton.text}
        </Button>
        <Button
          size='lg'
          bg='white'
          color='gray.800'
          _hover={{
            bg: 'gray.50'
          }}
          height='14'
          px='8'
          shadow='base'
          leftIcon={<Box as={HiPlay} fontSize='2xl' />}
          onClick={() => actions.handleDemo(props.tryDemoButton.link)}
        >
          {props.tryDemoButton.text}
        </Button>
      </Stack>
    </Hide>
  </Box>
)

export default FindBetterApplicantsSection
