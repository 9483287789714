import React from 'react'
import {
  Box,
  Button,
  Text
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

import { actions } from '../data'

const FreeForeverSection = ({ props }) => {
  return (
    <Box
      maxW={{
        base: '2xl',
        md: '7xl'
      }}
      mx={{ base: '2rem', lg: 'auto' }}
      px={{
        base: '6',
        lg: '24'
      }}
      py={{
        base: '6',
        lg: '12'
      }}
      backgroundColor='teal.600'
      marginTop='4'
      borderRadius='20'
    >
      <GatsbyImage
        image={getImage(props.sectionIcon.gatsbyImageData || '')}
        alt={props.title}
        style={{ marginBottom: 10 }}
      />
      <Text
        fontSize='3xl'
        color='white'
        fontWeight='bold'
      >
        {props.title}
      </Text>
      <Text
        color='whiteAlpha.700'
        fontSize='lg'
      >
        {props.content}
      </Text>
      <Box mt='8'>
        <Button
          variant='unstyled'
          fontSize='md'
          color='white'
          onClick={actions.handlePricing}
          fontWeight='semibold'
          rightIcon={<FontAwesomeIcon icon={faArrowRight} size='xs' />}
        >
          {props.pricingButton.text}
        </Button>
      </Box>
    </Box>
  )
}

export default FreeForeverSection
