import * as React from 'react'
import {
  Box
} from '@chakra-ui/react'

import FreeForeverSection from './FreeForeverSection'
import FindBetterApplicantsSection from './FindBetterApplicantsSection'
import StepsSection from './StepsSection'
import Testimonial from './Testimonal'
import WhyUse from './WhyUse'
import ScreenHero from './ScreenHero'

const UseCases = ({ useCaseData, setOpen }) => {
  return (
    <Box>
      <ScreenHero props={useCaseData.screenHero} openVideoModal={() => setOpen(true)} />
      <WhyUse props={useCaseData.whyRecruitersUseFaceFile} />
      <Testimonial props={useCaseData.whyRecruitersUseFaceFile.testimonial} />
      <StepsSection props={useCaseData.stepsSection} />
      <FreeForeverSection props={useCaseData.freeForeverSection} />
      <FindBetterApplicantsSection props={useCaseData.findBetterApplicantsSection} />
    </Box>
  )
}
export default UseCases
