import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import UseCases from '../../components/Usecases/UseCasesTenant'
import ModalVideoComponent from '../../components/ModalVideo'

const TenantPrescreening = ({ data }) => {
  const [isOpen, setOpen] = React.useState(false)
  const useCaseData = data.contentfulTenantPreScreening

  return (
    <Layout>
      <Seo title='FaceFile: One way video interviews to screen candidates faster' />
      <UseCases useCaseData={useCaseData} setOpen={() => setOpen(true)} />
      <ModalVideoComponent
        isOpen={isOpen}
        setOpen={setOpen}
        videoId={data.contentfulTenantPreScreening.screenHero.demoYoutubeVideoId}
      />
    </Layout>
  )
}

export default TenantPrescreening

export const query = graphql`
  query {
    contentfulTenantPreScreening {
      screenHero {
        title
        description
        demoYoutubeVideoId
        freePlanButton {
          text
        }
        demoButton {
          text
          link
        }
        noCardText
        heroImage {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
      whyRecruitersUseFaceFile {
        title
        useCaseCards {
          icon {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
          title
          content
        }
        testimonial {
          icon {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
          feedbackContent {
            feedbackContent
          }
          personImage {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
          personName
          personDesignation
        }
      }
      stepsSection {
        sectionTitle {
          raw
        }
        stepsItem {
          title
          text
          image {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
      freeForeverSection {
        sectionIcon {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
        title
        content
        pricingButton {
          text
        }
      }
      findBetterApplicantsSection {
        title
        startForFreeButton {
          text
        }
        tryDemoButton {
          text
          link
        }
      }
    }
  }
`
