import React from 'react'
import {
  Box,
  Text
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Testimonial = ({ props }) => (
  <Box backgroundColor='black' py='12'>
    <Box width='full' display='flex' justifyContent='center'>
      <GatsbyImage
        image={getImage(props.icon.gatsbyImageData)}
        alt={props.personDesignation || ''}
      />
    </Box>
    <Text
      fontSize='2xl'
      color='white'
      px='12'
      py='12'
      textAlign='center'
      width='3xl'
      mx='auto'
    >
      {props.feedbackContent.feedbackContent}
    </Text>
  </Box>
)

export default Testimonial
